.flat-container {
  padding: 15px;
  background-color: white;
}

.flat-container .icon-in-input {
  position: absolute;
  color: gray;
  top: 21px;
  right: 19px;
}

.flat-container input {
  width: 50%;
  margin-left: 10px;
  padding: 6px;
  padding-right: 40px;
  font-size: 20px;
}

.flat-container button {
  background-color: #3a5e7b;
  color: white;
  padding: 5px 20px;
  font-size: 20px;
}

.flat-container hr {
  margin: 40px 20px;
  border-top: 2px solid #3a5e7b;
}

.flat-container .result {
  margin: 8px;
}

.flat-container .result a {
  min-width: 100px;
  margin-left: 10px;
  display: inline-block;
}

.flat-container .result span.snippet::before,
.flat-container .result span.snippet::after {
  content: "…";
  color: gray;
}
