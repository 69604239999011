div#root {
  max-width: 1000px;
  margin: auto;
  box-shadow: 0px 0px 11px -4px black;
}

.App {
  font-family: sans-serif;
}

.App a,
.App button.link-look {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #3a5e7b;
  font-weight: bold;
  font-size: 15px;
  text-decoration: none;
}

.App .markdown-top-bar a,
.markdown-top-bar button.link-look {
  padding-right: 15px;
  border-right: 1px solid gray;
  margin-right: 15px;
}

.App a:hover,
.App button.link-look:hover {
  color: #2c475e;
  text-decoration: underline;
}

.App .editor-container .source-input {
  box-sizing: border-box;
  width: 100%;
  min-height: 300px;
}

.sub-header {
  text-align: right;
  background-color: #6d90b0;
  margin: 0px;
  padding: 15px;
  color: white;
  border-bottom: 5px solid #9eadbd;
}

.sub-header h1 {
  margin: 0px;
}

pre.markdown {
  margin-right: auto;
  margin-left: auto;
  max-width: 790px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  background: white;
  text-wrap: wrap;
}

.App button.cancel {
  color: #b92828;
}

.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
