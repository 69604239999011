p.loading-text {
  text-align: center;
  font-size: 25px;
  color: #3a5e7b;
  font-weight: bold;
  padding-top: 25px;
}

@keyframes ldio-rnahtw5hodf {
  0% {
    top: 74px;
    left: 74px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 15px;
    left: 15px;
    width: 118px;
    height: 118px;
    opacity: 0;
  }
}
.ldio-rnahtw5hodf div {
  position: absolute;
  border-width: 26px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-rnahtw5hodf 1.1111111111111112s cubic-bezier(0, 0.2, 0.8, 1)
    infinite;
}
.ldio-rnahtw5hodf div:nth-child(1) {
  border-color: #3a5e7b;
  animation-delay: 0s;
}
.ldio-rnahtw5hodf div:nth-child(2) {
  border-color: #6d90b0;
  animation-delay: -0.5555555555555556s;
}
.loadingio-spinner-ripple-b9ctpvngrvf {
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
  background: none;
  margin: auto;
}
.ldio-rnahtw5hodf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-rnahtw5hodf div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
