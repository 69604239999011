table.latest-changes {
  margin: 15px;
  background-color: white;
}

table.latest-changes tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.latest-changes td {
  padding: 15px;
}

table.latest-changes thead tr {
  background-color: #3a5e7b;
  color: white;
  font-weight: bold;
}
