.history-subtitle,
.history-subtitle a {
  font-size: 25px;
}

.history-subtitle {
  margin-right: 15px;
}

.change-date {
  text-align: left;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 5px;
  font-family: monospace;
  direction: ltr;
  color: #2c475e;
  border-top: 3px dashed #2c475e;
}
