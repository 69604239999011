body {
  margin: 0;
  background: #eee;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    ".SFNSText-Regular",
    sans-serif;
  font-weight: 500;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.material-icons.md-18 {
  font-size: 18px;
}

code {
  direction: ltr;
  text-align: left;
}

header {
  background-color: #3a5e7b;
  color: white;
  padding: 15px;
  margin: 0px;
}

header a.main-link {
  font-size: 24px;
  font-weight: bold;
}

header a {
  color: inherit;
  text-decoration: none;
}

header a:visited {
  color: inherit;
}

header a:hover {
  color: #ccc;
}

header .menu {
  float: left;
}

header .menu a {
  font-size: 24px;
  padding-left: 18px;
  padding-right: 18px;
  border-left: 3px solid #9eadbd;
}

header .menu a:last-child {
  border-left: 0px solid black;
}

header button.clickable-menu {
  display: none;
  float: left;
}

.material-icons {
  vertical-align: bottom;
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 600px) {
  header .menu {
    float: none;
  }

  header .menu .menu-shown,
  header .menu .menu-hidden {
    position: absolute;
    z-index: 99;
    background-color: #6d90b0;
    display: block;
    left: 0px;
    top: 57px;
    width: 100%;
    transform-origin: top;
    transition: transform 0.5s ease;
  }

  header .menu .menu-shown {
    color: white;
    transform: scaleY(1);
  }

  header .menu .menu-hidden {
    color: transparent;
    transform: scaleY(0);
  }

  header .menu .menu-shown a,
  header .menu .menu-hidden a {
    display: block;
    border-top: 2px solid #3a5e7b;
    padding-top: 6px;
    padding-bottom: 6px;
    border-left: none;
  }

  header button.clickable-menu {
    user-select: none;
    display: inline-block;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
  }

  .mobile-only {
    display: initial;
  }
}

footer {
  background-color: #3a5e7b;
  color: white;
  padding: 15px;
  border-top: 5px solid #9eadbd;
  bottom: 0;
  position: relative;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  color: #9eadbd;
}

footer .legal-menu {
  user-select: none;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  left: 15px;
}

footer .legal-menu .menu-hidden {
  display: none;
}

footer .legal-menu .menu-shown {
  display: inline-block;
  position: absolute;
  bottom: 25px;
  left: -10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 3px solid #9eadbd;
  background-color: #3a5e7b;
}

footer .legal-menu .menu-shown a {
  display: block;
  padding: 10px;
  min-width: 150px;
  color: white;
  border-bottom: 2px solid #9eadbd;
}

footer .legal-menu .menu-shown a:last-child {
  border-bottom: none;
}

footer .legal-menu .menu-shown a:hover {
  background-color: #9eadbd;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

a[href^="http"]:after {
  font-family: "Material Icons";
  font-size: 12px;
  color: #9eadbd;
  content: " \f1e2";
}

footer button.clickable-menu {
  user-select: none;
  display: inline-block;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  font-size: 17px;
}
